
import { Link, useLocation } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { Navbar, Container, Nav } from 'react-bootstrap';

const Navigation = (props) => {
	let location = useLocation();

	let handleLogout = (e) => {
		e.preventDefault();
		localStorage.clear();
		window.location.href = "/";
		// props.oktaAuth.signOut();
		// navigate('/')
	}

	return (
		<>
			<Navbar bg="dark" variant="dark">
				<Container>
					<Navbar.Brand href="#home">EMPOWER </Navbar.Brand>
					<Nav className="me-auto">
						<Link to="/admin" className='nav-link'>AD User Checker</Link>
						<Link to="/logout" className='nav-link' onClick={e => handleLogout(e)} >Logout</Link>
					</Nav>
				</Container>
			</Navbar>
		</>
	)

}

export default withOktaAuth(Navigation);
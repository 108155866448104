import React, { Component, Fragment } from 'react';

class HelpMeForm extends Component {

	state = {
		page: {
			loaded: true,
			hasError: false,
		}
	}

	componentDidMount = async () => {
		let token = localStorage.getItem('access-token');

		if (token) {
			window.location.href = "/admin";
		} else {
			window.location.href = "/login"
		}

	}

	render() {
		return <>Loading...</>
	}
}

export default HelpMeForm

import { Component, Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';

class EmpowerTalentService extends Component {


	state = {
		componentStatus: {
			loaded: false,
			error: false,
			message: ""
		},
		employeeInfo: null
	}

	componentDidMount() {
		this.getEmployeeInfo(this.props['employee-id']);
	}

	getEmployeeInfo = (employee_id) => {
		let token = localStorage.getItem('access-token');
		axios.get(`/api/auth/get-profile`, {
			params: {
				employeeId: employee_id
			},
			headers: {
				'Authorization': `Bearer ${token}`
			},
		})
			.then(response => {
				let { content, error, success } = response.data.data;
				if (success) {

					if (content.hasOwnProperty("employeeProfileFullDetails") && content.employeeProfileFullDetails.length > 0) {
						let employeeData = content.employeeProfileFullDetails[0];
						if (employeeData.employee_personal_info_last_name !== "") {
							let { componentStatus, employeeInfo } = { ...this.state };
							componentStatus.loaded = true;
							employeeInfo = employeeData;
							this.setState({ ...this.state, componentStatus, employeeInfo });
						}

					} else {
						let { componentStatus } = { ...this.state };
						componentStatus.loaded = false;
						componentStatus.error = true;
						componentStatus.message = "No records found";
						this.setState({ ...this.state, componentStatus });
					}
				}
			})
			.catch(err => {
				let { componentStatus } = { ...this.state };
				componentStatus.loaded = false;
				componentStatus.error = true;
				componentStatus.message = "No records found";
				this.setState({ ...this.state, componentStatus });
			})
	}

	render() {
		let { componentStatus, employeeInfo } = this.state;

		let mobile = "";
		let email = "";


		if (employeeInfo !== null && employeeInfo.contact.length > 0) {
			let findMobile = employeeInfo.contact.find(c => c.employee_contact_info_type_code === "MOBILE");

			if (findMobile) {
				mobile = findMobile.employee_contact_info_contact_value;
			}

			let findEmail = employeeInfo.contact.find(c => c.employee_contact_info_type_code === "WORK_EMAIL");
			if (findEmail) {
				email = findEmail.employee_contact_info_contact_value;
			}
		}

		return (
			<Fragment>
				<h5 className="text-center">Empower Employee ID</h5>
				<div className='row mt-5'>
					{componentStatus.loaded ?
						<>
							<dl className="row">
								<dt className="col-sm-5">Account Status</dt>
								<dd className="col-sm-7">{employeeInfo.employee_account_account_status}</dd>

								<dt className="col-sm-5">Employment Status</dt>
								<dd className="col-sm-7">{employeeInfo.employee_account_employment_status_code}</dd>
							</dl>

							<h5>Profile</h5>

							<dl className="row">
								<dt className="col-sm-5">Firstname</dt>
								<dd className="col-sm-7">{employeeInfo.employee_personal_info_first_name}</dd>

								<dt className="col-sm-5">Middlename</dt>
								<dd className="col-sm-7">{employeeInfo.employee_personal_info_middle_name}</dd>

								<dt className="col-sm-5">Lastname</dt>
								<dd className="col-sm-7">{employeeInfo.employee_personal_info_last_name}</dd>

								<dt className="col-sm-5">Username</dt>
								<dd className="col-sm-7">{employeeInfo.employee_account_username}</dd>

								<dt className="col-sm-5">Gender</dt>
								<dd className="col-sm-7">{employeeInfo.employee_personal_info_gender}</dd>

								{/* <dt className="col-sm-5">Marital Status</dt>
								<dd className="col-sm-7">{employeeInfo.employee_personal_info_marital_status}</dd>

								<dt className="col-sm-5">Birthdate</dt>
								<dd className="col-sm-7">{employeeInfo.employee_personal_info_birthdate ? moment(employeeInfo.employee_personal_info_birthdate).format('MMM DD, YYYY') : ""}</dd> */}

								<dt className="col-sm-5">Phone</dt>
								<dd className="col-sm-7">{mobile}</dd>

								<dt className="col-sm-5">Email</dt>
								<dd className="col-sm-7">{email}</dd>

								{/* <dt className="col-sm-5">Address</dt> */}
								{/* <dd className="col-sm-7">
									{employeeInfo.addresses.length > 0 &&
										<>
											{employeeInfo?.addresses[0]?.employee_address_unit} {employeeInfo?.addresses[0]?.employee_address_block} {employeeInfo?.addresses[0]?.employee_address_phase} {employeeInfo?.addresses[0]?.city_name} {employeeInfo?.addresses[0]?.province_name}
										</>
									}
								</dd> */}

							</dl>
							<hr />
							<h5>Work</h5>
							<dl className="row">
								<dt className="col-sm-5">Job Title</dt>
								<dd className="col-sm-7">{employeeInfo.job_title_name}</dd>

								<dt className="col-sm-5">Department</dt>
								<dd className="col-sm-7">{employeeInfo.departmentInfo ? employeeInfo.departmentInfo.company_department_name : ""}</dd>

								<dt className="col-sm-5">Company</dt>
								<dd className="col-sm-7">{employeeInfo.company_details_name}</dd>

								<dt className="col-sm-5">Site</dt>
								<dd className="col-sm-7">{employeeInfo.site_name}</dd>

								<dt className="col-sm-5">Reporting To</dt>
								<dd className="col-sm-7">{employeeInfo.reportingTo ? employeeInfo.reportingTo.employee_personal_info_first_name : ""}</dd>

								<dt className="col-sm-5">Department Head</dt>
								<dd className="col-sm-7">{employeeInfo.departmentInfo ? employeeInfo.departmentInfo.department_head : ""}</dd>
							</dl>

						</>
						:
						componentStatus.error ?
							(
								<div className='col-12 text-center mt-5'>
									<p>{componentStatus.message} pertaining the employee ID of {this.props['employee-id']}</p>
								</div>
							)
							:
							(
								<div className='col-12 text-center align-self-center' style={{ marginTop: 100 }}>
									<Spinner animation="border" role="status" style={{ width: '7em', height: "7em" }}>
										<span className="visually-hidden">Loading...</span>
									</Spinner>
								</div>
							)


					}

				</div>
			</Fragment >
		)
	}
}


export default EmpowerTalentService;
// import React, { lazy } from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';


import Login from './pages/login.page';
import HelpMeForm from './pages/helpmeform';
import Admin from './pages/admin';
import 'bootstrap/dist/css/bootstrap.min.css';

import PrivateRoute from './routes/private.route';

const App = () => {

	return (
		<>
			<PrivateRoute path='/' exact={true} component={HelpMeForm} />
			<PrivateRoute path="/admin" component={Admin} />
			<Route path='/login' render={() => <Login />} />
			<Route path='/public' render={() => <div>Unauthorized</div>} />
		</>
	);
};

export default App;
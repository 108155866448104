import axios from 'axios';
import moment from 'moment';
import React, { Component, createRef } from 'react';
import Navigation from '../../components/navigation.components';

import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import { withRouter, useLocation } from 'react-router-dom';
import EmpowerTalentService from '../../components/empower.component';
class Admin extends Component {

	state = {
		page: {
			loaded: true,
			lookupPage: false
		},
		formErrors: [],
		formData: {
			username: '',
			password: ""
		},
		formType: {
			password: "password"
		},
		formStatus: {
			show: false,
			status: "error",
			type: "",
			message: ""
		},
		apiReturn: {
			ad: null
		},
		tabs: {
			default: 'check'
		}
	}

	inputChangeHandler = e => {
		let { formData, formErrors } = this.state;
		formData[e.target.name] = e.target.value;

		if (formErrors.includes(e.target.name)) {
			let idx = formErrors.indexOf(e.target.name);
			formErrors.splice(idx, 1);
		}

		this.setState({ ...this.state, formData, formErrors });
	}

	submitFormHandler = e => {
		e.preventDefault();
		let { formData, formErrors, page, tabs } = this.state;
		let errors = [];

		Object.keys(formData).map(key => {
			if (formData[key] === "") {
				errors.push(key);
			}
		})

		if (["check"].includes(tabs.default)) {

			if (errors.includes("password")) {
				let idx = errors.indexOf("password");
				errors.splice(idx, 1);
			}
		}
		if (errors.length > 0) {
			formErrors = errors;
			this.setState({ ...this.state, formErrors });
		} else {
			page.loaded = false;
			this.setState({ ...this.state, page }, () => this.getSearchResult());
		}
	}

	getSearchResult = () => {
		let endpoint = '/api/auth/ldap-auth';

		if (["check"].includes(this.state.tabs.default)) {
			endpoint = "/api/auth/check-user";
		}

		axios.post(endpoint, this.state.formData, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access-token')
			}
		}).then(response => {
			let { data } = response.data
			let { page, apiReturn, tabs, formStatus } = this.state
			page.loaded = true;

			if (data.isSuccess) {
				page.lookupPage = true;
				apiReturn.ad = tabs.default === "authenticate" ? data.content.userDetails : data.content;
			} else {
				page.loaded = true;
				formStatus.show = true;
				formStatus.status = "alert-danger";
				formStatus.message = data.error;

			}

			this.setState({ ...this.state, page, formStatus });
		}).catch(error => {
			let { formStatus, page } = this.state
			page.loaded = true;
			formStatus.show = true;
			formStatus.status = "alert-danger";
			formStatus.message = "Something went wrong. Please contact marvin from EMPOWER";
			if (error.response.status === 404) {
				formStatus.message = "Username or Password supplied is not valid.";
			}

			this.setState({ ...this.state, formStatus, page });
		})
	}

	tryAnotherHandler = e => {
		let { apiReturn, formStatus, formData, page, formErrors } = this.state
		apiReturn.ad = null;

		formStatus = {
			show: false,
			status: "error",
			type: "",
			message: ""
		}

		formData = {
			username: '',
			password: ""
		}

		page = {
			loaded: true,
			lookupPage: false
		};

		formErrors = [];

		this.setState({ ...this.state, apiReturn, formStatus, formData, page, formErrors });
	}


	// Tabs
	setTabKey = key => {
		let { tabs } = this.state;
		tabs.default = key;
		this.setState({ ...this.state, tabs });
	}

	render() {
		let { page, formData, formErrors, formStatus, apiReturn, formType, tabs } = this.state;

		return <>
			<Navigation />

			{page.loaded ?
				<div className='container mb-3'>

					{page.lookupPage ?
						<div className="row justify-content-center mt-3">
							{apiReturn !== null &&
								<>
									<div className='col-12 mt-3 mb-3'>
										<button
											className='btn btn-primary'
											style={{ float: "right" }}
											onClick={e => this.tryAnotherHandler(e)}
										>Try Another ?</button>
									</div>
									<div className='col-4'>
										<h5 className="text-center">Active Directory Lookup</h5>

										{tabs.default === "authenticate" ?
											<dl className="row">
												<dt className="col-sm-5">Email</dt>
												<dd className="col-sm-7">{apiReturn.ad.email}</dd>

												<dt className="col-sm-5">Employee ID</dt>
												<dd className="col-sm-7">{apiReturn.ad.employee_id}</dd>

												<dt className="col-sm-5">Username</dt>
												<dd className="col-sm-7">{apiReturn.ad.username}</dd>

												<dt className="col-sm-5">First Name</dt>
												<dd className="col-sm-7">{apiReturn.ad.first_name}</dd>

												<dt className="col-sm-5">Lastname</dt>
												<dd className="col-sm-7">{apiReturn.ad.last_name}</dd>

											</dl>

											:
											<dl>

												<dt className="col-sm-5">Username</dt>
												<dd className="col-sm-7">{apiReturn.ad['Samaccountname']}</dd>

												<dt className="col-sm-5">Name</dt>
												<dd className="col-sm-7">{apiReturn.ad['Name']}</dd>

												<dt className="col-sm-5">Account Status</dt>
												<dd className="col-sm-7">{apiReturn.ad['Account Status']}</dd>

												<dt className="col-sm-5">Password Last Set</dt>
												<dd className="col-sm-7">{apiReturn.ad['Password Last Set']}</dd>

												{apiReturn.ad.hasOwnProperty("Password Expiration") &&
													<>
														<dt className="col-sm-5">Password Expiration</dt>
														<dd className="col-sm-7">{apiReturn.ad['Password Expiration']}</dd>
													</>
												}

												<dt className="col-sm-5">Employee ID</dt>
												<dd className="col-sm-7">{apiReturn.ad['Employee ID']}</dd>

												<dt className="col-sm-5">Position</dt>
												<dd className="col-sm-7">{apiReturn.ad['Position']}</dd>

												<dt className="col-sm-5">Company</dt>
												<dd className="col-sm-7">{apiReturn.ad['Company']}</dd>

												<dt className="col-sm-5">Office</dt>
												<dd className="col-sm-7">{apiReturn.ad['Office']}</dd>

											</dl>
										}



									</div>
									<div className='col-8'>
										<div className='card'>
											<div className='card-body'>
												<EmpowerTalentService 
													employee-id={apiReturn.ad.employee_id || apiReturn.ad['Employee ID']} 
													userName={apiReturn.ad.username || apiReturn.ad['Samaccountname']}
												/>
											</div>
										</div>
									</div>
								</>
							}

						</div>
						:
						<div className="row justify-content-center">
							<div className="col-6 align-self-center mt-5">
								<div className='card'>
									<h5 className="card-header text-center">Active Directory Checker</h5>

									<div className='card-body'>
										<div className='row'>
											<div className='col-12'>

												<Tabs
													activeKey={tabs.default}
													onSelect={(k) => this.setTabKey(k)}
													className="mb-3"
												>
													{/* <Tab eventKey="authenticate" title="Authenticate">
														<form>
															<div className='row'>
																{formStatus.show &&
																	<div className='col-12'>
																		<div className={`alert ${formStatus.status}`} role="alert">
																			{formStatus.message}
																		</div>
																	</div>
																}

																<div className='col-12'>
																	<div className="form-group">
																		<label htmlFor="username_input">Username</label>
																		<input
																			type="text"
																			className={`form-control ${formErrors.includes('username') ? 'is-invalid' : ""}`}
																			id="username_input"
																			name="username"
																			value={formData.username}

																			onChange={e => this.inputChangeHandler(e)}
																		/>
																	</div>
																</div>

																<div className='col-12'>

																	<div className="form-group mt-3">
																		<label htmlFor="password">Password</label>
																		<input
																			id="password"
																			type={formType.password}
																			className={`form-control ${formErrors.includes('password') ? 'is-invalid' : ""}`}
																			aria-describedby="basic-addon2"
																			value={formData.password}
																			name="password"
																			onChange={e => this.inputChangeHandler(e)}
																		/>
																	</div>
																</div>

																<div className='col-12'>
																	<button
																		type="submit"
																		className="btn btn-primary mt-3"
																		style={{ float: 'right' }}
																		onClick={e => this.submitFormHandler(e)}
																	>Submit</button>
																</div>

															</div>
														</form>
													</Tab> */}
													<Tab eventKey="check" title="Check User Status">
														<form>
															<div className='row'>
																{formStatus.show &&
																	<div className='col-12'>
																		<div className={`alert ${formStatus.status}`} role="alert">
																			{formStatus.message}
																		</div>
																	</div>
																}
																<div className='col-12'>
																	<div className="form-group">
																		<label htmlFor="username">Username or Employee ID</label>
																		<input
																			type="text"
																			className={`form-control ${formErrors.includes('username') ? 'is-invalid' : ""}`}
																			id="username"
																			name="username"
																			value={formData.username}

																			onChange={e => this.inputChangeHandler(e)}
																		/>
																	</div>
																</div>

																<div className='col-12'>
																	<button
																		type="submit"
																		className="btn btn-primary mt-3"
																		style={{ float: 'right' }}
																		onClick={e => this.submitFormHandler(e)}
																	>Submit</button>
																</div>
															</div>
														</form>
													</Tab>

												</Tabs>


											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					}

				</div>
				: <div className='container'>
					<div className="row justify-content-center">
						<div className="col-6 align-self-center mt-5">
							<h3>Active directory authentication attempt...</h3>
						</div>
					</div>
				</div>
			}
		</>


	}
}


export default withRouter(Admin);

